<template>
  <a
    v-if="componentType === 'external'"
    :href="to"
    v-bind="{
      ...$attrs,
    }"
  >
    <span v-if="$attrs.ariaLabel" class="hidden">{{ $attrs.ariaLabel }}</span>
    <slot v-bind="attributes" />
  </a>

  <NuxtLink
    v-else-if="componentType === 'internal'"
    v-bind="{
      ...(!toRouteParam ? { href: attributes.to } : {}),
      ...(toRouteParam ? { to: toRouteParam } : {}),
      ...$attrs,
    }"
  >
    <slot v-bind="attributes" />
  </NuxtLink>

  <span v-else>
    <slot v-bind="attributes" />
  </span>
</template>
<script setup lang="ts">
import type { RouteLocationRaw, RouteLocationPathRaw } from 'vue-router';

type LinkType = 'passive' /* span */ | 'internal' /* NuxtLink */ | 'external' /* a */;

defineComponent({
  name: 'AppLink',
});

const props = defineProps({
  to: {
    type: String,
    required: false,
    default: '/',
  },
  toRouteParam: {
    type: Object as PropType<RouteLocationRaw | RouteLocationPathRaw | null>,
    required: false,
    default: () => null,
  },
  external: {
    type: Boolean,
    default: false,
  },
});

const { locale } = useI18n();

const componentType = computed<LinkType>(() => {
  if (!props.to) return 'passive';

  if (props.external) return 'external';

  if (typeof props.toRouteParam === 'object' && props.to) {
    return 'internal';
  }

  return 'internal';
});

const attributes = computed(() => {
  if (props.external)
    return {
      href: props.to,
      rel: 'noopener',
      target: 'blank',
    };

  return {
    to: !props?.to?.includes(`/${locale.value}/`) ? `/${locale.value}${props.to}` : props.to,
  };
});
</script>
